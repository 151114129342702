import { Button, useResponsiveBreakpoint } from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { MenuButtonDesktop } from '../Buttons';
import { dataTestIds } from '../constants';
import { AppsForProducts } from '../Menus/AppsForProducts';
import { Categories } from '../Menus/Categories';
import { Resources } from '../Menus/Resources';
import { expandableMenuLabel } from './constants';
import { BlanketTransitionContent } from './ExpandableMenu.styles';
import { BlanketTransition, DropdownMenuTransition } from './Transitions';
import { ExpandableMenuType } from './types';

import type { ExpandableMenuProps, ExpandableMenuRef } from './types';

const FeedbackButton = styled(Button)({
  '&&': {
    '&:focus-visible': {
      outlineOffset: 0,
    },
  },
});

const Blanket = ({ isActive, onClick }) => {
  return (
    <BlanketTransition isActive={isActive}>
      <BlanketTransitionContent
        data-testid={dataTestIds.EXPANDABLE_MENU__BLANKET}
        onClick={onClick}
      />
    </BlanketTransition>
  );
};

export const ExpandableMenuDesktop = forwardRef<ExpandableMenuRef, ExpandableMenuProps>(
  ({ isOpen, openedMenu, closeMenu, openMenu, openFeedbackDialog }, ref) => {
    const appsForProductsBtnRef = useRef(null);
    const categoriesBtnRef = useRef(null);
    const resourcesBtnRef = useRef(null);
    const focusFirstLinkUnderMenu = useRef<HTMLAnchorElement>(null);
    const isBlanketActive = isOpen && openedMenu !== ExpandableMenuType.MOBILE_VIEW;
    const { rawWidth } = useResponsiveBreakpoint();
    // Feedback button is only shown when the screen width is greater than 1124px
    // This is to ensure that the feedback button does not cause overflow due to cramped space
    const screenFeedbackButtonThreshold = 1124;

    const isMenuButtonActive = (menuType: ExpandableMenuType) => {
      return isOpen && openedMenu === menuType;
    };

    const onMenuButtonClick = (menuType: ExpandableMenuType) => {
      return isMenuButtonActive(menuType) ? closeMenu : openMenu(menuType);
    };

    useImperativeHandle(ref, () => ({
      get triggers() {
        return {
          [ExpandableMenuType.APPS_FOR_PRODUCTS]: appsForProductsBtnRef.current,
          [ExpandableMenuType.CATEGORIES]: categoriesBtnRef.current,
          [ExpandableMenuType.RESOURCES]: resourcesBtnRef.current,
          [ExpandableMenuType.FIRST_LINK_TO_FOCUS]: focusFirstLinkUnderMenu.current,
        };
      },
    }));

    return (
      <>
        <Blanket isActive={isBlanketActive} onClick={closeMenu} />
        {/* Apps for products menu */}
        <MenuButtonDesktop
          ref={appsForProductsBtnRef}
          isActive={isMenuButtonActive(ExpandableMenuType.APPS_FOR_PRODUCTS)}
          onClick={onMenuButtonClick(ExpandableMenuType.APPS_FOR_PRODUCTS)}
          label={expandableMenuLabel[ExpandableMenuType.APPS_FOR_PRODUCTS]}
          ariaLabel="Apps for products"
        />
        <DropdownMenuTransition isOpen={isOpen}>
          {openedMenu === ExpandableMenuType.APPS_FOR_PRODUCTS && (
            <AppsForProducts
              closeMenu={closeMenu}
              focusFirstLinkUnderMenu={focusFirstLinkUnderMenu}
            />
          )}
        </DropdownMenuTransition>

        {/* Categories menu */}
        <MenuButtonDesktop
          ref={categoriesBtnRef}
          isActive={isMenuButtonActive(ExpandableMenuType.CATEGORIES)}
          onClick={onMenuButtonClick(ExpandableMenuType.CATEGORIES)}
          label={expandableMenuLabel[ExpandableMenuType.CATEGORIES]}
          ariaLabel="App categories"
        />
        <DropdownMenuTransition isOpen={isOpen}>
          {openedMenu === ExpandableMenuType.CATEGORIES && (
            <Categories closeMenu={closeMenu} focusFirstLinkUnderMenu={focusFirstLinkUnderMenu} />
          )}
        </DropdownMenuTransition>

        {/* Resources menu */}
        <MenuButtonDesktop
          ref={resourcesBtnRef}
          isActive={isMenuButtonActive(ExpandableMenuType.RESOURCES)}
          onClick={onMenuButtonClick(ExpandableMenuType.RESOURCES)}
          label={expandableMenuLabel[ExpandableMenuType.RESOURCES]}
          ariaLabel="Marketplace resources"
        />
        <DropdownMenuTransition isOpen={isOpen}>
          {openedMenu === ExpandableMenuType.RESOURCES && (
            <Resources closeMenu={closeMenu} focusFirstLinkUnderMenu={focusFirstLinkUnderMenu} />
          )}
        </DropdownMenuTransition>
        {openFeedbackDialog && rawWidth > screenFeedbackButtonThreshold && (
          <FeedbackButton onClick={openFeedbackDialog}>Give Feedback</FeedbackButton>
        )}
      </>
    );
  }
);

ExpandableMenuDesktop.displayName = 'ExpandableMenuDesktop';
